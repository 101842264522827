






























































































































  import { Component, Prop, mixins, Watch } from 'nuxt-property-decorator'
  import { BindingHelpers } from 'vuex-class/lib/bindings'
  import { namespace } from 'vuex-class'
  import uuid from 'uuid-random'
  import { Currency, Products, Stocks } from '@one/types'
  import { Cart, Wishlist } from '@one/types/dist/orderpath/app'
  import inViewport from 'vue-in-viewport-mixin'
  import UtilsMixin from '~/mixins/UtilsMixin'
  import CurrentProductMixin from '~/mixins/CurrentProduct'
  import { ProductQuantityAndResourceId, WishlistInterface } from '~/components/organisms/product/OneProductTileRightActions.vue'

  import Warehouse = Stocks.Warehouse
  import { MappedProductStocks } from '~/store/stocks/types'

  interface WishlistWithQuantityInterface {
    wishlist: WishlistInterface
    quantity: number
  }

  const stocks: BindingHelpers = namespace('stocks')
  const cms: BindingHelpers = namespace('cms')
  const products: BindingHelpers = namespace('products')
  const categories: BindingHelpers = namespace('categories')

  @Component({
    mixins: [inViewport],
    components: {
      OneGallery: () => import('~/components/organisms/grid/product/OneGallery.vue'),
      OneEuEnergyLabel: () => import('~/components/organisms/product/OneEuEnergyLabel.vue'),
      OneProductTileGallery: () =>
        import('~/components/organisms/product/OneProductTileGallery.vue'),
      OneProductTileInformation: () =>
        import('~/components/organisms/product/OneProductTileInformation.vue'),
      OneProductTechnicalButton: () =>
        import('~/components/molecules/buttons/OneProductTechnicalButton.vue'),
      OneProductTileRightActions: () =>
        import('~/components/organisms/product/OneProductTileRightActions.vue'),
      OneProductStocksInfo: () => import('~/components/organisms/product/OneProductStocksInfo.vue'),
      OneProductTileRightPrice: () =>
        import('~/components/organisms/product/OneProductTileRightPrice.vue'),
      OneProductTitleLabels: () =>
        import('~/components/molecules/images/OneProductTitleLabels.vue'),
    },
  })
  export default class OneProductListView extends mixins(UtilsMixin, CurrentProductMixin) {
    @products.Getter getProduct: any
    @stocks.Getter getWarehouse: any
    @stocks.Getter getProductStocks: any
    @stocks.Getter getProductWarehouse: any
    @stocks.Getter getProductOrderQuantityInWarehouse: any
    @stocks.Getter getDefaultWarehouseId: any
    @stocks.Getter getCentralWarehouse: any
    @products.Getter getProductLabels: any
    @products.Getter getProductImages: any
    @categories.Getter getCategory: any
    @cms.Getter theme: any

    elemId: string = uuid()
    showCollapse: boolean = false
    mainComponentClass = 'one-product-list-view'
    @Prop({
      type: Boolean,
      default: false,
    })
    extended?: boolean

    @Prop({
      type: Boolean,
      default: true,
    })
    showRightSide!: boolean

    @Prop({
      type: Boolean,
      default: true,
    })
    showAddToWishlistButton?: boolean

    @Prop({
      type: Boolean,
      default: true,
    })
    showTechTriggerBtn?: boolean

    @Prop({
      type: String,
      required: true,
    })
    productId?: string

    @Prop({
      required: true,
      type: String,
    })
    unit?: string

    @Prop({
      type: Object,
    })
    prices?: Products.ProductPrice

    @Prop({
      type: Boolean,
    })
    priceLoading?: boolean

    @Prop({
      type: Boolean,
    })
    stocksLoading?: boolean

    @Prop({
      type: Object,
    })
    currency?: Currency

    @Prop({
      type: Boolean,
      required: false,
      default: false,
    })
    progress?: boolean

    @Prop({
      type: Boolean,
      required: false,
      default: false,
    })
    progressWishlist?: boolean

    @Prop({
      type: Array,
      required: true,
    })
    carts!: Array<Cart.Responses.BasicCartInfo>

    @Prop({
      type: Array,
      required: true,
    })
    wishlists!: Array<Wishlist.Responses.BasicWishlist>

    @Prop({
      required: true,
      type: String,
    })
    loginToBuyLabel!: string

    @Watch('inViewport.now')
    isInViewport() {
      // @ts-ignore
      if (this.inViewport.now) {
        this.$plugins.onMounted(this.mainComponentClass, this, {
          productId: this.productId,
        })
      }
    }

    onProductClick(product) {
      const price = this.getProductPrice(product.id)
      this.$gtm.selectItem({
        item_id: product.id,
        item_name: product.name,
        item_brand: (product.brand && product.brand.name),
        category: this.$routes.getCategoryPath(product.canonicalCategory) || '',
        price: price?.price || 0,
        discount: price?.catalogPrice - price?.price,
      }, 'one-product-list-view')
    }

    mediumPhotoExtended(id: string): string {
      return this.getProductImages(id, this.extended ? 'SMALL_300' : 'SMALL_200')
    }

    onAddToCart({ id, quantity }: ProductQuantityAndResourceId) {
      this.$emit('add-to-cart', {
        id,
        productId: this.product.id,
        amountOfProducts: quantity,
      })
    }

    onAddToWishlist({ wishlist, quantity }: WishlistWithQuantityInterface) {
      this.$emit('add-to-wishlist', {
        wishlist,
        productId: this.product.id,
        amountOfProducts: quantity,
      })
    }

    warehouse(id: string) {
      return this.getProductWarehouse(this.productId, id)
    }

    getLabel(id: string) {
      return this.warehouse(id) ? this.warehouse(id).warehouseLabel : '-'
    }

    getShippingTime(id: string) {
      return this.getWarehouse(id) && this.getWarehouse(id).shippingTime
    }

    get shouldBeInDOM() {
      /**
       * <extended> is currently only used on product details view.
       * We want to force the element to stay in DOM so when warehouses status popup is open,
       * and contains many elements, user can scroll the page and view full list.
       */
      // @ts-ignore
      return this.inViewport.now || this.extended
    }

    get isProductBlocked(): boolean {
      return this.getProductLabels('Block', this.productId)
    }

    get categoryName(): string {
      const category = this.getCategory(this.product.canonicalCategory)
      return category ? category.name : null
    }

    get categoryId(): string {
      const category = this.getCategory(this.product.canonicalCategory)
      return category ? category.id : null
    }

    get product() {
      return this.getProduct(this.productId)
    }

    get productH1(): string {
      return this.product?.seo?.h1
    }

    get stocks(): MappedProductStocks | null {
      return this.getProductStocks(this.productId)
    }

    get warehouses() {
      if (!this.stocks) {
        return []
      }
      return this.stocks.warehouses.allIds
        .map((stockId: string) => ({
          id: stockId,
          unity: this.stocks!.unit,
          quantity: this.getProductOrderQuantityInWarehouse(this.productId, stockId),
          label: this.getLabel(stockId),
          shippingTime: this.getShippingTime(stockId),
          type: this.getWarehouse(stockId).type,
        }))
        .filter((item: any) => {
          if (this.parentWarehouseId) {
            return item.id !== this.getDefaultWarehouseId && item.id !== this.parentWarehouseId
          }

          return item.id !== this.getDefaultWarehouseId
        })
        .sort((a: any, b: any): number => {
          if (a.label < b.label) {
            return -1
          }
          if (a.label > b.label) {
            return 1
          }
          return 0
        })
    }

    get parentWarehouseId(): string {
      return this.parentWarehouse?.id || ''
    }

    get hasSelectedWarehouseParent(): boolean {
      return this.parentWarehouseId !== null
    }

    get currentWarehouse(): Warehouse {
      return this.getWarehouse(this.getDefaultWarehouseId)
    }

    get parentWarehouse(): Warehouse {
      return this.currentWarehouse && this.getWarehouse(this.currentWarehouse.parent)
    }

    get firstCentralWarehouse(): string {
      return this.getCentralWarehouse && this.getCentralWarehouse[0]
    }

    get parentOrFirstWarehouseId(): string {
      return (this.parentWarehouse && this.parentWarehouse.id) || this.firstCentralWarehouse
    }

    get isSelectedWarehouseCentral() {
      return this.currentWarehouse.type.toString() === 'CENTRAL'
    }

    get labels(): any {
      return {
        firstWarehouse: this.getLabel(this.parentWarehouseId),
        defaultWarehouse: this.getLabel(this.getDefaultWarehouseId),
      }
    }

    get shippingTime(): any {
      return {
        firstWarehouse: this.getShippingTime(this.parentWarehouseId),
        defaultWarehouse: this.getShippingTime(this.getDefaultWarehouseId),
      }
    }

    getQuantity(productId: string, defaultWarehouseId: string) {
      const product = this.getProduct(productId)

      return this.isTileCalculator(product)
        ? this.stocks?.warehouses.byId[defaultWarehouseId]?.quantity
        : this.getProductOrderQuantityInWarehouse(productId, defaultWarehouseId)
    }

    destroyed() {
      this.$plugins.onUnmounted(this.mainComponentClass)
    }
  }
