import { createDecorator } from 'vue-class-component'
import { InjectOptions } from 'vue-property-decorator/lib/decorators/Inject'
import { InjectKey } from 'vue/types/options'

export const reactiveInjectKey = '__reactiveInject__'

export function InjectReactive(options?: InjectOptions | InjectKey) {
  return createDecorator((componentOptions, key) => {
    if (typeof componentOptions.inject === 'undefined') {
      componentOptions.inject = {}
    }
    if (!Array.isArray(componentOptions.inject)) {
      const fromKey = options ? (options as any).from || options : key
      const defaultVal = (!!options && (options as any).default) || undefined
      if (!componentOptions.computed) { componentOptions.computed = {} }
      componentOptions.computed![key] = function () {
        const obj = (this as any)[reactiveInjectKey]
        return obj ? obj[fromKey] : defaultVal
      }

      // @ts-ignore
      componentOptions.inject[reactiveInjectKey] = { key: reactiveInjectKey, default: defaultVal }
    }
  })
}
